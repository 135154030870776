import { IUser } from "./UserModels";

export interface IGlobalDispatch {
	user: IUser;
	missingTranslations: IMissingTranslations;
	setMissingTranslations: React.Dispatch<
		React.SetStateAction<IMissingTranslations>
	>;
	setError: (error: any) => void;
	setCurrentHelpTopic: any;
	setResetOrdersFilters: React.Dispatch<React.SetStateAction<boolean>>;
	resetOrdersFilters: boolean;
	setResetStylesFilters: React.Dispatch<React.SetStateAction<boolean>>;
	resetStylesFilters: boolean;
	setResetTemplatesFilters: React.Dispatch<React.SetStateAction<boolean>>;
	resetTemplatesFilters: boolean;
	setResetPrintBatchesFilters: React.Dispatch<React.SetStateAction<boolean>>;
	resetPrintBatchesFilters: boolean;
	fromOrders: React.MutableRefObject<boolean>;
	factoryBrandsRequestApproval: string[] | undefined;
	setConfirmationProps: React.Dispatch<
		React.SetStateAction<IConfirmationProps>
	>;
}

export interface IAppDispatch {
	setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
	setNewVersion: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IMissingTranslations {
	missingMadeIn?: any;
	missingCareInstructions?: any;
	missingContentComposition?: any;
	missingAdditionalComponents?: any;
	missingComponents?: any;
	missingFibers?: any;
}

export interface IConfirmationProps {
	message: string;
	link: string;
}

export const languagesCodeFullname: { [key: string]: string } = {
	ar: "Arabic",
	bg: "Bulgarian",
	bn: "Bengali",
	cs: "Czech",
	da: "Danish",
	de: "German",
	el: "Greek",
	en: "English",
	es: "Spanish",
	fa: "Persian (Farsi)",
	fr: "French",
	hi: "Hindi",
	hu: "Hungarian",
	id: "Indonesian",
	it: "Italian",
	ja: "Japanese",
	jv: "Javanese",
	ko: "Korean",
	ms: "Malay",
	nl: "Dutch",
	no: "Norwegian",
	pl: "Polish",
	pt: "Portuguese",
	ro: "Romanian (Moldavian)",
	ru: "Russian",
	sk: "Slovak",
	sv: "Swedish",
	ta: "Tamil",
	th: "Thai",
	tl: "Tagalog",
	tr: "Turkish",
	ur: "Urdu",
	vi: "Vietnamese",
	yo: "Yoruba",
	"pt-BR": "Portuguese (Brasil)",
	"sr-Cyrl": "Serbian (Cyrillic)",
	"sr-Latn": "Serbian (Latin)",
	"zh-Hans": "Chinese simplified",
	"zh-Hant": "Traditional Chinese",
};

export const dataTypesForTranslations: { [key: string]: string } = {
	madeIn: "Made In",
	careInstructions: "Care Instructions",
	fibers: "Fibers",
	components: "Components",
	additionalComponents: "Additional Components",
};

export type TypeLabelTemplateDppInfo = {
	bg_color?: string;
	language_select_border_color?: string;
	card_bg_color?: string;
	card_font_color?: string;
	card_logo_bg_color?: string;
	gradient_color_1?: string;
	gradient_color_2?: string;
	logo_width?: number;
	hide_style_number?: boolean;
	hide_style_description?: boolean;
	hide_season?: boolean;
	black_icons_color?: boolean;
};

export const demoDppData = {
	entities: {
		phase: 1,
		styleNumber: "Style number",
		size: "",
		symbolSet: "usa",
		translations: {
			en: {
				contentComposition: "100% ACETATE",
				additionalComponents: [],
				careInstructions: [
					{
						symbol: "DoNotBleach.png",
						name: "DO NOT BLEACH",
					},
					{
						symbol: "DoNotDryClean.png",
						name: "DO NOT DRY CLEAN",
					},
					{
						symbol: "DoNotTumbleDry.png",
						name: "DO NOT TUMBLE DRY",
					},
					{
						symbol: "DoNotWash.png",
						name: "DO NOT WASH",
					},
					{
						symbol: "DryCleanOnly.png",
						name: "DRY CLEAN ONLY",
					},
				],
				additionalInformations: [
					{
						name: "DESIGNED IN NEW YORK",
					},
					{
						name: "FABRIC OF ITALIAN ORIGIN",
					},
				],
				madeIn: "MADE IN USA OF US AND IMPORTED MATERIALS",
			},
		},
		brandId: 23,
		expiry_date: "2024-03-01T14:28:42.216Z",
		styleDescription: "Desc",
		season: "PF24",
		templateId: 27,
		hash: "612e50453f703bfa16f8c2e74dadcc3f0fab406031b0fe6d049db16d1bd35212",
		blackLabel: false,
	},
};

export const appEnvSymbolsStorageName = {
	development: "cacotec-care-instructions-symbols-local",
	staging: "cacotec-care-instructions-symbols-stg",
	sandbox: "cacotec-care-instructions-symbols-sandbox",
	production: "cacotec-care-instructions-symbols-production",
};
