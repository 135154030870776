import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import useDataApi from "Hooks/fetchHook";
import Loading from "../Shared/Loading";
import {
	TypeLabelTemplateDppInfo,
	demoDppData,
	languagesCodeFullname,
} from "Models/GlobalModels";

import Languages from "images/LanguagesGlobe.svg";
import AdditionalInfo from "images/AdditionalInfo.svg";
import ContentComposition from "images/ContentComposition.svg";
import MadeIn from "images/Location.svg";
import CacotecLogo from "images/logo.svg";
import { useFetchLabelTemplates } from "Hooks/queryHooks/useFetchLabelTemplates";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";
import { brandDppAppLabelTemplates } from "Utils/utils";
import sprite from "images/icons.svg";

const DppCustomization: React.FunctionComponent = () => {
	const {
		user: { account_type_id, org_logo },
		setError,
	} = useContext(GlobalDispatch);

	const { labelTemplates } = useFetchLabelTemplates(account_type_id, setError);

	const useSaveDppCustomizationData = useDataApi();

	const [currentLanguageCode, setCurrentLanguageCode] = useState("en");

	let defaultDppSettings = {
		bg_color: "#f4f4f4",
		language_select_border_color: "#c0e79c",
		card_bg_color: "#ffffff",
		card_font_color: "#000000",
		card_logo_bg_color: "#ffffff",
		gradient_color_1: "#9dfbc8",
		gradient_color_2: "#f5f186",
		logo_width: 70,
		black_icons_color: true,
	};

	let labelTemplateForCustomization =
		brandDppAppLabelTemplates(labelTemplates)[0];

	const [customInfo, setCustomInfo] = useState<TypeLabelTemplateDppInfo>(
		labelTemplateForCustomization.settings.dpp_settings || defaultDppSettings
	);

	/* *************************************************************************** */

	useEffect(() => {
		const { data } = useSaveDppCustomizationData;

		if (data.status === "ok") {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.LABEL_TEMPLATES, account_type_id],
			});
			toast.success("Successfully saved DPP style settings.");
		}
	}, [useSaveDppCustomizationData.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveDppCustomizationData;
		if (error) {
			toast.error(`Unable to save DPP customization data. ${error}`);
		}
	}, [useSaveDppCustomizationData.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */

	const getSymbol = (currentLanguageCode: string) => {
		if (currentLanguageCode === "zh-Hans") {
			return "china";
		} else return "usa";
	};

	let {
		entities: { translations, styleNumber, styleDescription, season, size },
	} = demoDppData as any;

	let styleInfoArray = [
		!customInfo?.hide_style_number && styleNumber,
		!customInfo?.hide_style_description && styleDescription,
		!customInfo?.hide_season && season,
	].filter((styleInfo: any) => styleInfo);

	let gradient = `linear-gradient(to right,${
		customInfo?.gradient_color_1 || defaultDppSettings.gradient_color_1
	} 0%,${
		customInfo?.gradient_color_2 || defaultDppSettings.gradient_color_2
	} 100%)`;

	let cardBgColor =
		customInfo?.card_bg_color || defaultDppSettings.card_bg_color;
	let logoCardBgColor =
		customInfo?.card_logo_bg_color || defaultDppSettings.card_logo_bg_color;
	let cardFontColor =
		customInfo?.card_font_color || defaultDppSettings.card_font_color;

	let blackIconsColor =
		typeof customInfo?.black_icons_color === "boolean"
			? customInfo?.black_icons_color
			: defaultDppSettings.black_icons_color;

	let whiteIconFilter = !blackIconsColor
		? { filter: "brightness(0) invert(1)" }
		: {};

	const onSaveDppCustomizationData = () => {
		useSaveDppCustomizationData.doFetch(
			`/brand/data/dppSettings`,
			{
				dppSettings: customInfo,
				labelTemplateId: labelTemplateForCustomization.id,
			},
			"POST"
		);
	};

	return (
		<div
			className="tab-pane fade in active show"
			role="tabpanel"
			id="tab-general"
			aria-labelledby="tab-toggle-general"
		>
			<fieldset
				className="box box--light mb--md flex"
				style={{
					padding: "40px 60px",
					justifyContent: "space-evenly",
					flexWrap: "wrap",
				}}
			>
				<div className="mr--lg" style={{ width: "300px" }}>
					<h1>DPP style</h1>
					<div className="mt--sm">
						Here you can set the style for the DPP page which is shown to the
						users once they scan the QR code on your label.
					</div>
					<div className="mb--base mt--base flex-center-secondary-axis justify-between">
						<label className="" htmlFor="logo_width">
							Logo size
						</label>
						<div className="flex-center-secondary-axis">
							<input
								type="range"
								min={30}
								max={100}
								className="ml--sm"
								name="logo_width"
								id="logo_width"
								value={customInfo.logo_width || defaultDppSettings.logo_width}
								onChange={(e) =>
									setCustomInfo((prevCustomInfo) => ({
										...prevCustomInfo,
										logo_width: +e.target.value,
									}))
								}
							/>
							<span className="ml--base mr--xs">
								{customInfo.logo_width || defaultDppSettings.logo_width}
							</span>
						</div>
					</div>
					<div className="mb--sm flex-center-secondary-axis justify-between">
						<label className="" htmlFor="bg_color">
							Main background color
						</label>
						<input
							type="color"
							className="ml--sm color-picker"
							name="bg_color"
							id="bg_color"
							value={customInfo.bg_color || defaultDppSettings.bg_color}
							onChange={(e) =>
								setCustomInfo((prevCustomInfo) => ({
									...prevCustomInfo,
									bg_color: e.target.value,
								}))
							}
						/>
					</div>
					<div className="mb--sm flex-center-secondary-axis justify-between">
						<label className="" htmlFor="card_logo_bg_color">
							Logo background color
						</label>
						<input
							type="color"
							className="ml--sm color-picker"
							name="card_logo_bg_color"
							id="card_logo_bg_color"
							value={
								customInfo.card_logo_bg_color ||
								defaultDppSettings.card_logo_bg_color
							}
							onChange={(e) =>
								setCustomInfo((prevCustomInfo) => ({
									...prevCustomInfo,
									card_logo_bg_color: e.target.value,
								}))
							}
						/>
					</div>
					<div className="mb--sm flex-center-secondary-axis justify-between">
						<label className="" htmlFor="language_select_border_color">
							Language select border color
						</label>
						<input
							type="color"
							className="ml--sm color-picker"
							name="bg_color"
							id="bg_color"
							value={
								customInfo.language_select_border_color ||
								defaultDppSettings.language_select_border_color
							}
							onChange={(e) =>
								setCustomInfo((prevCustomInfo) => ({
									...prevCustomInfo,
									language_select_border_color: e.target.value,
								}))
							}
						/>
					</div>
					<div className="mb--sm flex-center-secondary-axis justify-between">
						<label className="" htmlFor="card_bg_color">
							Cards background color
						</label>
						<input
							type="color"
							className="ml--sm color-picker"
							name="card_bg_color"
							id="card_bg_color"
							value={
								customInfo.card_bg_color || defaultDppSettings.card_bg_color
							}
							onChange={(e) =>
								setCustomInfo((prevCustomInfo) => ({
									...prevCustomInfo,
									card_bg_color: e.target.value,
								}))
							}
						/>
					</div>
					<div className="mb--sm flex-center-secondary-axis justify-between">
						<label className="" htmlFor="card_bg_color">
							Cards font color
						</label>
						<input
							type="color"
							className="ml--sm color-picker"
							name="card_font_color"
							id="card_font_color"
							value={
								customInfo.card_font_color || defaultDppSettings.card_font_color
							}
							onChange={(e) =>
								setCustomInfo((prevCustomInfo) => ({
									...prevCustomInfo,
									card_font_color: e.target.value,
								}))
							}
						/>
					</div>
					<div className="mb--sm flex-center-secondary-axis justify-between">
						<label className="" htmlFor="gradient_color_1">
							Gradient color - left
						</label>
						<input
							type="color"
							className="ml--sm color-picker"
							name="gradient_color_1"
							id="gradient_color_1"
							value={
								customInfo.gradient_color_1 ||
								defaultDppSettings.gradient_color_1
							}
							onChange={(e) =>
								setCustomInfo((prevCustomInfo) => ({
									...prevCustomInfo,
									gradient_color_1: e.target.value,
								}))
							}
						/>
					</div>
					<div className="mb--sm flex-center-secondary-axis justify-between">
						<label className="" htmlFor="gradient_color_2">
							Gradient color - right
						</label>
						<input
							type="color"
							className="ml--sm color-picker"
							name="gradient_color_2"
							id="gradient_color_2"
							value={
								customInfo.gradient_color_2 ||
								defaultDppSettings.gradient_color_2
							}
							onChange={(e) =>
								setCustomInfo((prevCustomInfo) => ({
									...prevCustomInfo,
									gradient_color_2: e.target.value,
								}))
							}
						/>
					</div>

					<div className="mb--sm flex-center-secondary-axis justify-between">
						<div>Icons color</div>
						<div className="language-slider" style={{ width: "120px" }}>
							<button
								className={`slider-option ${blackIconsColor ? "active" : ""}`}
								onClick={() =>
									setCustomInfo((prevCustomInfo) => ({
										...prevCustomInfo,
										black_icons_color: !blackIconsColor,
									}))
								}
								style={{ padding: "5px 0px" }}
							>
								Black
							</button>
							<button
								className={`slider-option ${!blackIconsColor ? "active" : ""}`}
								onClick={() =>
									setCustomInfo((prevCustomInfo) => ({
										...prevCustomInfo,
										black_icons_color: !blackIconsColor,
									}))
								}
								style={{ padding: "5px 0px" }}
							>
								White
							</button>
							<div className="slider-highlight"></div>
						</div>
					</div>

					<div className="mt--lg mb--md flex">
						<div>
							{!useSaveDppCustomizationData.isLoading && (
								<div
									className="button button--primary button--md"
									onClick={() => {
										onSaveDppCustomizationData();
									}}
								>
									Save
								</div>
							)}
							<Loading
								show={useSaveDppCustomizationData.isLoading}
								text={"Loading..."}
								imgClass="saveOrderLoading"
								divClass=""
							/>
						</div>
					</div>
				</div>
				<div className="modern-phone">
					<div className="notch"></div>
					<div className="modern-screen">
						<main
							style={{
								backgroundColor:
									customInfo?.bg_color || defaultDppSettings.bg_color,
								width: "420px",
							}}
						>
							<div className="w-full flex flex-column items-center">
								<div
									className="w-full flex flex-column justify-center"
									style={{ padding: "36px 16px 16px" }}
								>
									<div
										className="flex justify-center"
										data-tooltip-id="logo_card_bg_color"
										style={{
											backgroundColor: logoCardBgColor,
											marginBottom: "8px",
											padding: "8px 24px",
										}}
									>
										<img
											src={org_logo}
											alt="Brand Logo"
											style={{
												width: `${
													customInfo?.logo_width ||
													defaultDppSettings.logo_width
												}%`,
												maxWidth: "100%",
											}}
										/>
									</div>
									<div
										className="flex"
										style={{
											background: gradient,
											marginBottom: "16px",
											padding: "8px",
										}}
									>
										<div className="flex">
											<div
												className="flex bg--light select-language-wrapper"
												style={{
													borderColor:
														customInfo?.language_select_border_color ||
														defaultDppSettings.language_select_border_color,
												}}
											>
												<div
													className="flex justify-center items-center"
													style={{ width: "34px", flexShrink: 0 }}
												>
													<img
														src={Languages}
														width={34}
														height={34}
														alt="Choose language"
														style={{ borderRadius: "48px" }}
													/>
												</div>
												<select
													name="languages"
													value={currentLanguageCode}
													className="select-background-color-safari"
													onChange={(e) => {
														setCurrentLanguageCode(e.target.value);
													}}
												>
													{Object.keys(translations).map(
														(languageCode: string) => {
															return (
																<option key={languageCode} value={languageCode}>
																	{languagesCodeFullname[languageCode]}
																</option>
															);
														}
													)}
												</select>
											</div>
										</div>
									</div>
									{styleInfoArray.length > 0 && (
										<div
											className="cacotec-bottom-border flex"
											style={{
												borderImageSource: gradient,
												backgroundColor: cardBgColor,
												color: cardFontColor,
											}}
										>
											<div
												className="flex justify-center items-center"
												style={{ width: "56px", flexShrink: 0 }}
											>
												<svg
													className="icon ml--xs mr--sm"
													style={{
														width: "28px",
														height: "28px",
														fill: "black",
														...whiteIconFilter,
													}}
												>
													<use xlinkHref={`${sprite}#icon-order-item`} />
												</svg>
											</div>
											<div className="flex items-center">
												<span>
													{styleInfoArray.map((styleInfo, index) => {
														return `${styleInfo}${
															index < styleInfoArray.length - 1 ? " - " : ""
														}`;
													})}
												</span>
											</div>
										</div>
									)}
									{size && (
										<div
											className="cacotec-bottom-border flex"
											style={{
												borderImageSource: gradient,
												backgroundColor: cardBgColor,
												color: cardFontColor,
											}}
										>
											<div style={{ width: "56px" }}></div>
											{size}
										</div>
									)}
									{translations[currentLanguageCode]?.madeIn && (
										<div
											className="cacotec-bottom-border flex"
											style={{
												borderImageSource: gradient,
												backgroundColor: cardBgColor,
												color: cardFontColor,
											}}
										>
											<div
												className="flex justify-center items-center"
												style={{ width: "56px", flexShrink: 0 }}
											>
												<img
													src={MadeIn}
													width={29}
													height={29}
													alt="Made in"
													style={whiteIconFilter}
												/>
											</div>
											<div className="flex items-center">
												<span>{translations[currentLanguageCode].madeIn}</span>
											</div>
										</div>
									)}
									{translations[currentLanguageCode]?.contentComposition && (
										<div
											className="cacotec-bottom-border flex"
											style={{
												borderImageSource: gradient,
												backgroundColor: cardBgColor,
												color: cardFontColor,
											}}
										>
											<div
												className="flex justify-center items-center"
												style={{ width: "56px", flexShrink: 0 }}
											>
												<img
													src={ContentComposition}
													width={30}
													height={30}
													alt="Content composition"
													style={whiteIconFilter}
												/>
											</div>
											<div className="flex items-center">
												<span>
													{translations[currentLanguageCode].contentComposition}
												</span>
											</div>
										</div>
									)}
									{translations[currentLanguageCode]?.careInstructions && (
										<div
											className="cacotec-bottom-border"
											style={{
												borderImageSource: gradient,
												backgroundColor: cardBgColor,
												color: cardFontColor,
											}}
										>
											{translations[currentLanguageCode].careInstructions.map(
												(careInstruction: {
													name: string;
													symbol?: string;
												}) => {
													let symbolSet = getSymbol(currentLanguageCode);
													let careInstructionSymbolUrl = `careInstructionsIcons/${symbolSet}/black/${careInstruction.symbol}`;

													return (
														<div
															key={careInstruction.name}
															className="flex items-center"
														>
															<div
																className="flex justify-center"
																style={{
																	height: "43px",
																	width: "56px",
																	flexShrink: 0,
																}}
															>
																{careInstruction.symbol && (
																	<img
																		src={careInstructionSymbolUrl}
																		width={32}
																		height={43}
																		alt="Care instruction"
																		style={whiteIconFilter}
																	/>
																)}
															</div>
															<div>{careInstruction.name}</div>
														</div>
													);
												}
											)}
										</div>
									)}
									{translations[currentLanguageCode]?.additionalComponents
										.length > 0 && (
										<div
											className="cacotec-bottom-border"
											style={{
												borderImageSource: gradient,
												backgroundColor: cardBgColor,
												color: cardFontColor,
											}}
										>
											{translations[
												currentLanguageCode
											].additionalComponents.map(
												(additionalComponent: { name: string }) => {
													return (
														<div
															key={additionalComponent.name}
															className="flex"
														>
															<div
																className="flex justify-center"
																style={{
																	width: "56px",
																	alignItems: "flex-start",
																	transform: "scale(0.9)",
																	flexShrink: 0,
																}}
															></div>
															<div className="ml-2 flex items-center">
																<span>{additionalComponent.name}</span>
															</div>
														</div>
													);
												}
											)}
										</div>
									)}
									{translations[currentLanguageCode]?.additionalInformations
										.length > 0 && (
										<div
											className="cacotec-bottom-border"
											style={{
												borderImageSource: gradient,
												backgroundColor: cardBgColor,
												color: cardFontColor,
											}}
										>
											{translations[
												currentLanguageCode
											].additionalInformations.map(
												(additionalInformation: { name: string }) => {
													return (
														<div
															key={additionalInformation.name}
															className="flex"
														>
															<div
																className="flex justify-center"
																style={{
																	height: "43px",
																	width: "56px",
																	alignItems: "center",
																	flexShrink: 0,
																}}
															>
																<img
																	style={whiteIconFilter}
																	src={AdditionalInfo}
																	width={33}
																	height={33}
																	alt="Additional info"
																/>
															</div>
															<div className="flex items-center">
																<span>{additionalInformation.name}</span>
															</div>
														</div>
													);
												}
											)}
										</div>
									)}
									<div className="flex justify-center">
										<img
											src={CacotecLogo}
											style={{
												marginBlock: "16px",
												paddingBlock: "5px",
												...whiteIconFilter,
											}}
											width={"20%"}
											alt="Cacotec logo"
										/>
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export default DppCustomization;
